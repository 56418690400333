.verticalFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100vw - 3rem);
}

.verticalFormContainer.alignLeft {
  align-items: flex-start;
}

.verticalFormContainer.alignRight {
  align-items: flex-end;
}

@media (--viewportTabletMin) {
  .verticalFormContainer {
    width: 352px;
  }
}

@media (--viewportDesktopMin) {
  .verticalFormContainer {
    width: 416px;
  }
}

.verticalFormContainer > form {
  width: 100%;
}
