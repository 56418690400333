.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  padding-top: 3rem;
  padding-bottom: 6rem;
}

.container.centered {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
}

.logoContainer {
  padding-bottom: 3rem;
}

.header {
  width: 100%;
}

.progressBar {
  margin-top: 16px;
  margin-bottom: 2rem;
}

.body {
  width: 100%;
}

.heading {
  margin-bottom: 0;
  composes: ui20SemiBold from '../../../../styles/typeography.css';
}

.subhead {
  composes: ui20Medium from '../../../../styles/typeography.css';
  margin-bottom: 1rem;
  font-weight: 500;
  color: var(--colorGray6);
}

.caption {
  margin-top: 8px;
  margin-bottom: 16px;
  color: var(--colorGray7);
  composes: ui14Normal from '../../../../styles/typeography.css';
}

.hasSubhead .caption {
  margin-top: 16px;
}

.container:not(.hasSubhead):not(.hasCaption):not(.hasProgress) .heading {
  margin-bottom: 32px;
}

.fullWidth {
  width: fit-content;
}
