.track {
  display: flex;
  height: 0.5rem;
  width: 100%;
  background-color: var(--colorProgressTrack);
  border-radius: 0.25rem;
  overflow: hidden;
}

.track.thin {
  height: 0.25rem;
  border-radius: 0.125rem;
}

.bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--colorProgressBar);
  border-radius: 0.25rem;
  transition: all 285ms ease-in-out;
}

.bar.thin {
  border-radius: 0.125rem;
}
